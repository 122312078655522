@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

@media screen and (max-width: 768px) {
  .emoji-image-1 {
    background-size: 400vw;
    background-position-x: left;
  }

  .emoji-image-2 {
    background-size: 500vw;
    background-position-x: -360vw;
  }

  .header-container-open,
  .header-container-closed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(59, 53, 93, 0.8);
    align-items: center;
    margin: 0px;
    padding: 20px;
    position: fixed;
    width: 100%;
  }

  .header-container-hidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(59, 53, 93, 0.8);
    align-items: center;
    margin: 0px;
    padding: 20px;
    position: fixed;
    width: 100%;
  }

  .header-items {
    display: none;
  }

  .header-item {
    cursor: pointer;
    font-weight: bold;
    margin: 0vw 1vw 0vw 1vw;
  }

  .sidebar-items {
    margin-right: 40px;
    margin-top: 30px;
    width: 290px;
    background-color: #3b355d;
    align-self: center;
    padding: 20px;
    border-radius: 20px;
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.226));
    margin-bottom: 100vh;
  }

  .sidebar-item {
    display: flex;
    cursor: pointer;
    font-weight: bold;
    margin: 10px 0px 10px 0px;
  }

  .selected-link {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 20px;
    background-color: #2aacd3;
    border-radius: 20px;
    border-bottom: none;
    margin: 0px 0px 0px 0px;
    font-size: medium;
  }

  .unselected-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    margin: 0px 5px 0px 5px;
    font-size: medium;
  }

  .unselected-link:hover {
    border-bottom: none;
    margin: 0px;
  }

  .hamburger-menu {
    cursor: pointer;
    align-self: flex-end;
    margin-right: 30px;
  }

  .hamburger-line {
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px;
  }

  .close-button {
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;
    margin-right: 35px;
  }
  .close-line-1 {
    width: 30px;
    height: 3px;
    background: white;
    transform: translateY(3px) rotate(45deg);
  }

  .close-line-2 {
    width: 30px;
    height: 3px;
    background: white;
    transform: rotate(-45deg);
  }

  .modal-container {
    width: 330px;
    background-color: white;
    color: black;
    border-radius: 20px;
    margin: 7px;
  }

  .modal-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-title-text {
    font-size: large;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 15px 15px 0px 15px;
  }

  .modal-body-text {
    font-size: medium;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 7px 20px 7px 20px;
  }

  .blue-modal-btn {
    font-size: small;
  }

  .green-modal-btn {
    font-size: small;
    width: 50%;
  }

  .modal-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ff4087;
    color: white;
    font-size: medium;
    text-decoration: none;

    width: 250px;
    height: 60px;
    border-radius: 20px;
    margin: 10px;
  }

  .primetime-logo {
    width: 100px;
  }

  .title-header {
    font-size: large;
  }

  .title-subheader {
    font-size: medium;
    margin-bottom: 50px;
  }

  .primetime-description {
    font-size: small;
    text-align: left;
    margin: 10%;
  }

  [data-aos-delay] {
    transition-delay: 10 !important;
  }

  [data-aos] {
    transition: none;
  }

  .game-screenshots {
    margin: 50px;
    width: 100%;
  }

  div.faq-text-content h1 {
    font-size: large;
  }
  div.faq-text-content h2 {
    font-size: medium;
  }

  div.faq-text-content p {
    font-size: small;
  }

  .faq-text-content {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-header {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-container {
    padding: 10%;
    padding-bottom: 0px;
  }

  .p-work {
    text-align: center;
  }

  .h1-work {
    text-align: center;
  }

  .h1-privacy {
    text-align: center;
  }

  .h1-about {
    padding: 0px;
    margin: 0px;
    font-size: large;
  }

  .support-interactive-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titlecard-container {
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin-bottom: 7px;
  }

  .titlecard-title {
    font-size: large;
    padding: 0px;
    margin: 30px 30px 0px 30px;
    width: auto;
    height: auto;
  }

  .titlecard-body {
    font-size: medium;
    font-weight: normal;
    padding: 0px;
    margin: 0px 30px 30px 30px;
    width: auto;
    height: auto;
  }

  .cm-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #353055;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .open-cm-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .homepage-cm-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .insta-container {
    display: flex;
    flex-direction: column;
  }

  .cm-title-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cm-logo-and-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .cm-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin: 15px;
  }

  .cm-subtitle-text {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    font-weight: medium;
  }

  .cm-title-text {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .cm-body-container-open {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    transition: max-height 0.1s ease-in-out;
  }

  .cm-body-container-closed {
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;
  }

  .faq-question {
    font-size: large;
  }

  .faq-answer {
    font-size: medium;
  }

  .schedule-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    width: 330px;
    border-radius: 0px 0px 20px 20px;
    margin-bottom: 7px;
  }
  .schedule-content p,
  h2 {
    font-size: medium;
  }

  .schedule-content {
    padding: 20px;
  }

  .schedule-title-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .schedule-title {
    font-weight: bold;
  }

  .schedule-date {
    color: grey;
  }

  .schedule-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 10px;
  }

  .primetime-loading-gif {
    align-self: center;
    margin: 20px;
  }

  .quizzes-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    color: black;
    width: 330px;
    border-radius: 0px 0px 20px 20px;
    margin: 0px 7px 7px 7px;
  }

  .quiz-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 10px;
  }

  .quiz-content {
    padding: 20px;
  }

  .quiz-content p,
  a {
    font-size: medium;
  }

  .quiz-content a {
  }

  .quizzes-title-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .quizzes-title-header h2 {
    padding: 0px;
    margin: 0px;
    font-size: large;
  }
  .quiz-title {
    font-weight: bold;
    font-size: small;
  }

  .quiz-link {
    display: flex;
    text-decoration: none;
    background-color: #2aacd3;
    color: white;
    padding: 10px;
    margin: 0px 20px 0px 20px;
    height: 35px;
    border-radius: 20px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .watch-broadcast-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #2aacd3;
    color: white;
    text-decoration: none;

    width: 250px;
    height: 60px;
    border-radius: 20px;
  }

  .watch-broadcast-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .advertiser-description-container p {
    font-size: small;
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .pink-dot {
    background-color: #ec5387;
  }

  .blue-dot {
    background-color: #2aacd3;
  }

  .advertisers-container {
    padding: 10%;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .advertisers-description-container {
    background-color: white;
    color: black;
    font-size: medium;
    width: 330px;
    border-radius: 20px;
  }

  .advertisers-description-content {
    padding: 15px;
  }

  .advertisers-description-title {
    display: flex;
    justify-content: center;
    font-size: large;
    font-weight: bold;
    margin-top: 15px;
  }

  .advertisers-description-body {
    margin: 10px 0px 10px 0px;
  }

  .advertisers-description-body h2 {
    font-size: medium;
    margin: 0px;
  }

  .advertisers-description-body p {
    font-size: medium;
    margin: 0px;
  }

  .advertisers-description-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .advertisers-description-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ff4087;
    color: white;
    font-size: small;

    width: 250px;
    height: 60px;
    border-radius: 20px;
  }

  .partners-description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 330px;
    border-radius: 20px;
    background-color: white;
    color: black;
    margin: 7px 0px 7px 0px;
  }

  .partners-description-content {
    padding: 15px;
  }

  .partners-description-text {
  }

  .partners-description-title {
    margin: 0px;
    margin-top: 5px;
    font-size: large;
    width: 80%;
  }

  .partners-description-body {
    margin: 0px;

    font-size: medium;
  }

  .partner-description-dots {
    margin: 5px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .partner-description-dot {
    margin: 5px;
    display: flex;
    flex-direction: row;
    font-size: small;
    font-weight: lighter;
    justify-content: flex-start;
    align-items: center;
  }

  .dtc-container {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-wide {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-wide-about-us {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-content {
    padding: 15px;
  }

  .dtc-pretitle {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    margin-top: 5px;
  }

  .dtc-title {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .ltc-container {
    width: 330px;
    background-color: #fff;
    border-radius: 20px;
    margin: 7px;
    color: black;
  }

  .ltc-content {
    padding: 15px;
    margin-bottom: 10px;
  }

  .ltc-pretitle {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    margin-top: 5px;
  }

  .ltc-title {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .page-content-homepage {
    padding-top: 20vw;
  }

  .homepage-start-container {
    margin-bottom: 0px;
    height: auto;
  }

  .dark-modal-container {
    background-color: #353055;
    color: white;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
  }

  .dark-modal-content {
    padding: 15px;
  }

  .dark-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .dm-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .dark-modal-header-text {
  }

  .dm-subtitle-text {
    margin: 0px;
    padding: 0px;
    font-size: small;
    font-weight: medium;
  }

  .dm-title-text {
    margin: 0px;
    padding: 0px;
    font-size: large;
  }

  .dark-modal-body {
    font-size: small;
  }

  .dark-modal-body p {
    margin: 10px 0px 0px 0px;
  }

  .screenshot-section {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: max-content;
  }
  .game-screenshot-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .game-screenshot {
    width: 250px;
    /* filter: drop-shadow(0px 0px 20px #00000083); */
    /* margin:40px;  */
  }

  .first-row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-top: 30px;
    overflow: hidden;
  }

  .second-row-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0px;
    overflow: hidden;
    overflow-y: visible;
  }

  .thrd-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fourth-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fifth-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .map {
    width: 280px;
  }

  div.faq-text-content h1 {
    font-size: large;
  }
  div.faq-text-content h2 {
    font-size: medium;
  }

  div.faq-text-content p {
    font-size: small;
  }

  div.faq-text-content-about p {
    font-size: small;
  }

  .faq-text-content-about {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-header {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-container-about {
    padding: 20%;
    padding-top: 4vw;
    padding-bottom: 0vw;
  }

  .faq-text-container-header {
    padding: 20%;
    padding-top: 20vw;
    margin-top: 0px;
    padding-bottom: 0vw;
  }

  .dark-container {
    padding-top: 8vw;
    padding-bottom: 5vw;
  }

  .p-work {
    text-align: center;
  }

  .h1-work {
    text-align: center;
  }

  .h1-about {
    padding: 0px;
    margin: 0px;
  }

  .card-container {
    max-width: 90vw;
    margin: 0px;
    display: flex;
    margin-left: 0vw;
    margin-right: 0vw;
    align-self: center;
  }

  .card-grid {
    display: flex;
    flex-direction: column;
  }

  .card-br {
    line-height: 100px;
  }

  .contact-form-super-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
    min-height: 420px;
  }

  .contact-form-title {
    color: black;
    text-align: center;
    margin: 0px;
    font-size: large;
    margin: 30px 30px 7px 30px;
  }

  .contact-form-subtitle {
    color: black;
    text-align: center;
    font-size: medium;
    margin: 7px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: #575171;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    width: 280px;
    height: 41px;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
  }

  .select-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: grey;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    height: 41px;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    padding: 1rem;
    padding-right: 2rem;
    box-sizing: content-box;
    width: 280px;
  }

  .form-message-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: #575171;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    width: 280px;
    height: 100px;
    padding: 10px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
    resize: none;
  }

  .form-submit-btn {
    cursor: pointer;
    background-color: #2aacd3;
    color: white;
    font-family: "Poppins";
    font-size: medium;
    text-decoration: none;
    border: none;
    width: 250px;
    height: 60px;
    border-radius: 20px;
    margin: 7px 0px 15px 0px;
    -webkit-appearance: none;
  }

  .mail-sent-confirmation h1 {
    color: black;
    text-align: center;
    margin: 0px;
    font-size: x-large;
    margin: 15px 15px 7px 15px;
  }

  .mail-sent-confirmation p {
    color: black;
    margin: 0px;
    text-align: center;
    font-size: medium;
    margin: 7px 30px 15px 30px;
  }

  /* new hp design */

  .homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max-content;
    width: 70%;
    margin-bottom: 0px;
  }

  .hp-box {
    width: 330px;
    align-self: center;
    font-size: large;
    text-align: left;
    padding-bottom: 0px;
    margin: 50px;
    background-color: white;
    color: black;
    border-radius: 20px;
  }

  .hp-box-text {
    font-size: medium;
    font-weight: lighter;
  }

  h1 {
    font-size: 34px;
    padding: 0px;
    margin: 0px;
  }

  .hp-shortways {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .work-container {
    display: flex;
    flex-direction: column;
    width: 330px;
    margin-top: 30px;
  }

  .work-box {
    width: 330px;
    align-self: center;
    font-size: medium;
    text-align: center;
    margin-top: 80px;
  }

  .big-picture {
    border-radius: 25px;
    width: 330px;
    height: fit-content;
    margin-top: 40px;
  }

  .slider {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-image {
    position: static;
    width: 330px;
    height: 169px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    background-color: #353055;
  }

  .color-block {
    background-color: rgba(248, 247, 216, 0.7);
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slider-overlay {
    position: static;
    width: 330px;
    height: 100%;
    background-color: #ec5387;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
  }

  .slider-constants {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
  }

  .right-arrow {
    font-size: xx-large;
    color: white;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s;
  }

  .left-arrow {
    font-size: xx-large;
    color: white;
    cursor: pointer;
    transition: transform 0.3s;
  }

  .left-arrow:hover,
  .right-arrow:hover {
    transform: translateY(-5px);
    transition: transform 0.3s;
  }

  .slides-big-hl {
    font-size: large;
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
  }

  .slides-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .slides-paper {
    font-size: large;
    font-weight: bold;
    font-family: "Poppins";
    margin-left: 15px;
    margin-bottom: 0px;
  }

  .slides-text {
    font-size: medium;
    font-weight: lighter;
    font-family: "Poppins";
    margin: 15px;
  }

  .slides-body {
    font-size: medium;
    font-weight: normal;
    font-family: "Poppins";
    margin-left: 15px;
  }

  .slides-link {
    color: white;
    font-size: medium;
    font-weight: medium;
    font-style: italic;
    font-family: "Poppins";
  }

  .slider-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .image-color {
    background-color: yellow;
    position: absolute;
  }

  .press-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  .press-text-containers {
    display: flex;
    flex-direction: row;
  }
}
