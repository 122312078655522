@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
html,
body {
  font-family: "Poppins";
  color: white;
  margin: 0;
  padding: 0;
  background-color: #493e76;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
}

.header-container-open,
.header-container-closed {
  -webkit-transform: translateY(0px) translateZ(2px);
          transform: translateY(0px) translateZ(2px);
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgb(59, 53, 93, 0.8);
  top: 0;
  padding: 20px 0px 20px 0px;
  z-index: 999;
  width: 100%;
  transition: max-height 1s, -webkit-transform 0.8s;
  transition: transform 0.8s, max-height 1s;
  transition: transform 0.8s, max-height 1s, -webkit-transform 0.8s;
}

.header-container-hidden {
  -webkit-transform: translateY(-200px);
          transform: translateY(-200px);
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgb(59, 53, 93, 0.8);
  top: 0;
  padding: 20px 0px 20px 0px;
  z-index: 999;
  width: 100%;
  max-height: 100px;
  transition: max-height 1s, -webkit-transform 0.8s;
  transition: transform 0.8s, max-height 1s;
  transition: transform 0.8s, max-height 1s, -webkit-transform 0.8s;
}

.header-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-item {
  cursor: pointer;
  font-weight: bold;
  margin: 0px 40px 0px 40px;
}

.selected-link {
  text-decoration: none;
  color: white;
  border-bottom: 2px solid white;
}

.unselected-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.unselected-link:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.default-page-container {
  /* Set height to match that of the viewport. */
  width: 100vw;
  min-height: 100vh;

  background-image: url(/static/media/primetimeemojis.12b3f609.webp);

  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  padding-top: 50px;
}

.emoji-image-1 {
  background-size: 300vw;
  background-position-x: left;
}

.emoji-image-2 {
  background-size: 300vw;
  background-position-x: right;
}

.default-page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 15vw;
}

.modal-container {
  width: 330px;
  background-color: white;
  color: black;
  border-radius: 20px;
  margin: 7px;
}

.homepage-start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.modal-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-title-text {
  font-size: large;
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin: 15px 15px 0px 15px;
}

.modal-body-text {
  font-size: medium;
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin: 7px 20px 7px 20px;
}

.blue-modal-btn {
  font-size: medium;
}

.green-modal-btn {
  font-size: medium;
  width: 50%;
}

.modal-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ff4087;
  color: white;
  font-size: medium;
  text-decoration: none;

  width: 250px;
  padding: 0px 20px 0px 20px;
  height: 60px;
  border-radius: 20px;
  margin: 10px;
}

.modal-btn:active {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.blue-modal-btn-2 {
  background-color: #2aacd3;
  color: white;
  margin-bottom: 10px;
  width: 25%;
}

.modal-btn-2 {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-decoration: none;

  font-weight: bold;

  border-radius: 1vw;
  margin-top: 10px;
  padding: 20px;
}

.modal-btn-2 {
  font-size: medium;
}

.blue-modal-btn-2 {
  width: 50%;
}

.primetime-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.primetime-logo {
  width: 10vw;
  margin-bottom: 10px;
}

.title-header {
  font-size: 3vw;
  font-weight: 700;
  text-align: center;
}
.title-subheader {
  font-size: 2.5vw;
  font-weight: medium;
  text-align: center;
  margin-bottom: 50px;
}

.game-screenshots {
  width: 100%;
  overflow: hidden;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  background-color: #3b355d;
  padding-bottom: 20px;
}

.footer-items {
  display: flex;
  flex-direction: column;
}

.footer-item {
  cursor: pointer;
  font-size: medium;
  padding: 20px 0px 0px 50px;
}

.footer-some-icons {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: flex-end;
  margin-right: 10px;
}

.some-img {
  cursor: pointer;
  margin-right: 10px;
  height: 30px;
  -webkit-filter: invert(99%) sepia(0%) saturate(1113%) hue-rotate(8deg)
    brightness(119%) contrast(100%) opacity(0.5);
          filter: invert(99%) sepia(0%) saturate(1113%) hue-rotate(8deg)
    brightness(119%) contrast(100%) opacity(0.5);
}

.some-img:hover {
  -webkit-filter: invert(99%) sepia(0%) saturate(1113%) hue-rotate(8deg)
    brightness(119%) contrast(100%) opacity(0.9);
          filter: invert(99%) sepia(0%) saturate(1113%) hue-rotate(8deg)
    brightness(119%) contrast(100%) opacity(0.9);
}

.selected-footer-link {
  text-decoration: none;
  color: white;
  border-bottom: 2px solid white;
}

.unselected-footer-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.unselected-footer-link:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.support-interactive-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8vw;
}

.faq-text-content {
  width: 50vw;
  background-color: white;
  color: black;
  padding: 50px;
  border-radius: 20px;
  margin-top: 50px;
}

.h1-privacy {
  text-align: center;
}

.findUs-page-content {
  padding-top: 10vw;
  height: 100vh;
}

.map {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 10px;
  width: 44vw;
}

.titlecard-container {
  background-color: white;
  width: 50vw;
  border-radius: 20px;
  color: black;
  margin-bottom: 7px;
}

.titlecard-title {
  font-size: xx-large;
  padding: 0px;
  margin: 30px 30px 0px 30px;
  width: auto;
  height: auto;
}

.titlecard-body {
  font-size: medium;

  padding: 0px;
  margin: 10px 30px 30px 30px;
  width: auto;
  height: auto;
}

.cm-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #353055;
  width: 50vw;
  border-radius: 20px;
  margin: 7px;
  transition: background-color 0.2s ease-in-out;
}

.open-cm-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 50vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  color: black;
  margin: 7px;
  transition: background-color 0.2s ease-in-out;
}

.homepage-cm-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 674px;
  border-radius: 20px;
  color: black;
  margin: 7px;
  transition: background-color 0.2s ease-in-out;
}

.cm-title-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cm-logo-and-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cm-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 34, 73);
  width: 70px;
  height: 70px;
  font-size: 48px;
  border-radius: 20px;
  margin: 15px;
  margin-left: 30px;
}

.cm-subtitle-text {
  margin: 0px;
  padding: 0px;

  font-size: medium;
  font-weight: medium;
}

.cm-title-text {
  margin: 0px;
  padding: 0px;
  font-size: x-large;
}

.cm-body-container-open {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  transition: max-height 0.1s ease-in-out;
}

.cm-body-container-closed {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
}

.faq-question {
  font-size: large;
}

.faq-answer {
  font-size: medium;
}

.schedule-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  width: 660px;
  border-radius: 0px 0px 20px 20px;
  margin: 0px 7px 50px 7px;
}
.schedule-content p,
h2 {
  font-size: medium;
}

.schedule-content {
  padding: 20px;
}

.schedule-title-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.schedule-title {
  font-weight: bold;
}

.schedule-date {
  color: grey;
}

.schedule-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 34, 73);
  width: 70px;
  height: 70px;
  font-size: 48px;
  border-radius: 20px;
  margin-right: 10px;
}

.schedule-error-body {
  font-size: medium;
  margin: 15px;
}

.primetime-loading-gif {
  align-self: center;
  margin: 20px;
}

.quizzes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  color: black;
  width: 660px;
  border-radius: 0px 0px 20px 20px;
  margin: 0px 7px 50px 7px;
}

.quiz-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 34, 73);
  width: 70px;
  height: 70px;
  font-size: 48px;
  border-radius: 20px;
  margin-right: 10px;
}

.quiz-content {
  padding: 20px;
}

.quiz-content p,
a {
  font-size: medium;
}

.quiz-content a {
}

.quizzes-title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px 0px 15px;
}

.quizzes-title-header h2 {
  padding: 0px;
  margin: 0px;
  font-size: x-large;
}
.quiz-title {
  font-weight: bold;
  font-size: medium;
}

.quiz-link {
  display: flex;
  text-decoration: none;
  background-color: #2aacd3;
  color: white;
  padding: 10px;
  margin: 0px 20px 0px 20px;
  height: 35px;
  border-radius: 20px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.watch-broadcast-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #2aacd3;
  color: white;
  text-decoration: none;

  width: 250px;
  height: 60px;
  border-radius: 20px;
}

.watch-broadcast-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.advertiser-description-container p {
  font-size: medium;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-right: 15px;
}

.pink-dot {
  background-color: #ec5387;
}

.blue-dot {
  background-color: #2aacd3;
}

.advertisers-container {
  padding-bottom: 0vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8vw;
}

.advertisers-description-container {
  background-color: white;
  color: black;

  width: 50vw;
  border-radius: 20px;
}

.advertisers-description-content {
  padding: 15px;
}

.advertisers-description-title {
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: bold;
  margin-top: 15px;
}

.advertisers-description-body {
  margin: 10px 0px 10px 0px;
}

.advertisers-description-body h2 {
  font-size: medium;
  margin: 0px;
}

.advertisers-description-body p {
  font-size: medium;
  margin: 0px;
}

.advertisers-description-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.advertisers-description-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ff4087;
  color: white;
  font-size: medium;

  width: 250px;
  height: 60px;
  border-radius: 20px;
}

.partners-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50vw;
  border-radius: 20px;
  background-color: white;
  color: black;
  margin: 15px 0px 15px 0px;
}

.partners-description-content {
  padding: 15px;
}

.partners-description-text {
}

.partners-description-title {
  margin: 0px;
  margin-top: 5px;
  font-size: x-large;
  width: 80%;
  padding: 15px;
}

.partners-description-body {
  margin: 0px;
  font-size: medium;
}

.partner-description-dots {
  margin: 5px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.partner-description-dot {
  margin: 5px;
  display: flex;
  flex-direction: row;
  font-size: medium;
  font-weight: lighter;
  justify-content: flex-start;
  align-items: center;
}

.page-content-homepage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 8vw;
}

.dtc-container {
  width: 660px;
  background-color: #353055;
  border-radius: 20px;
  margin: 7px;
  margin-top: 50px;
}

.dtc-wide {
  width: 330px;
  background-color: #ff4087;
  background-color: #353055;
  border-radius: 20px;
  margin: 7px;
  margin-bottom: 0px;
}

.dtc-wide:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.dtc-wide-about-us {
  width: 50%;
  background-color: #353055;
  border-radius: 20px;
  margin: 7px;
}

.dtc-content {
  display: flex;
  flex-direction: row;
  padding: 15px;
  text-decoration: none;
  color: white;
}

.insta-container {
  display: flex;
  flex-direction: row;
}

.homepage-link-blocks {
  text-decoration: none;
}

.dtc-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 34, 73);
  width: 70px;
  height: 70px;
  font-size: 48px;
  border-radius: 20px;
  margin-right: 15px;
}

.dtc-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dtc-pretitle {
  margin: 0px;
  padding: 0px;
  font-size: medium;
  margin-top: 5px;
}

.dtc-title {
  margin: 0px;
  padding: 0px;
  font-size: x-large;
}

.ltc-container {
  width: 300px;
  background-color: #fff;
  border-radius: 20px;
  margin: 7px;
  color: black;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}

.ltc-content {
  padding: 15px;
}

.ltc-pretitle {
  margin: 0px;
  padding: 0px;
  font-size: medium;
  margin-top: 5px;
}

.ltc-title {
  margin: 0px;
  padding: 0px;
  font-size: x-large;
}

.dark-modal-container {
  background-color: #353055;
  color: white;
  width: 330px;
  border-radius: 20px;
  margin: 7px;
}

.dark-modal-content {
  padding: 15px;
}

.dark-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dm-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 34, 73);
  width: 70px;
  height: 70px;
  font-size: 48px;
  border-radius: 20px;
  margin-right: 15px;
}

.dm-subtitle-text {
  margin: 0px;
  padding: 0px;
  font-size: medium;
  font-weight: medium;
}

.dm-title-text {
  margin: 0px;
  padding: 0px;
  font-size: large;
}

.dark-modal-body {
  font-size: medium;
}

.dark-modal-body p {
  margin: 10px 0px 0px 0px;
}

.screenshot-section {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 110vh;
  /* background-color: #fa6dcf; */
}

.game-screenshot-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: inherit;
  align-items: flex-start;
}

.game-screenshot {
  width: 250px;
  /* filter: drop-shadow(0px 0px 20px #00000083); */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 31px;
  margin: 40px;
}

.first-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin: 10px;
  overflow: hidden;
}

.second-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin: 50px;
  overflow: hidden;
}

.thrd-row-container {
}

.fourth-row-container {
}

.fifth-row-container {
}

.expanded-modal-body {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: medium;
}

.expanded-modal-body,
p {
  margin-top: 0px;
}

.dark-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2vw;
  padding-bottom: 5vw;
}

.dark-container-text {
  width: 50vw;
  background-color: rgb(59, 53, 93);
  color: white;
  border-radius: 20px;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.card-container {
  max-width: 90vw;
  margin: 0px;
  display: flex;
  margin-left: 10vw;
  margin-right: 10vw;
  align-self: center;
}

.card-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 330px;
  height: 285px;
  /* transition: transform 1s; */
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  color: black;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  transition: 200ms;
  margin: 7px;
  cursor: pointer;
  -webkit-transform: perspective(1000px) rotateY(0)
    translateY(0);
          transform: perspective(1000px) rotateY(0)
    translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

.card:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}

.card.flip {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card .front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 16px;
  -webkit-transform: rotateX(0deg) translateZ(1px);
          transform: rotateX(0deg) translateZ(1px);
}

.card .back {
  cursor: auto;
  padding: 0px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: rotateY(-180deg) translateZ(0px);
          transform: rotateY(-180deg) translateZ(0px);
}

.card-text {
  text-align: left;
  font-size: smaller;
  margin: 15px 20px 20px 20px;
  padding-bottom: 0px;
  height: 215px;
  margin-bottom: 5px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.24);
  overflow: scroll;
}

.card-text h2 {
  margin: 0px;
  margin-top: 5px;
  font-size: small;
}

.card-text p {
  margin: 0px;
  font-size: small;
}

.card-mail {
  position: fixed;
  font-size: small;
  font-weight: lighter;
  color: white;
  text-align: right;
  background-color: #493e76;
  border-radius: 0px 10px 10px 0px;
  align-self: center;
  margin-right: 60px;
  padding: 5px 20px 5px 10px;
  cursor: auto;
}

.back {
  justify-content: left;
  position: relative;
}
.faq-text-container-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0vw;
}

.card-pp {
  width: 100%;
  height: 200px;
  object-fit: cover;
  align-self: center;
  object-position: center top;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.about-text-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.h1-host {
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-top: -5px;
  padding-bottom: 20px;
  font-size: x-large;
}

.h2-host {
  font-size: medium;
  margin-left: 15px;
  margin-top: 5px;
}

.h2-host-long {
  font-size: small;
  margin-left: 15px;
  margin-top: 10px;
}

.host-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 30px;
}

.contact-form-super-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 50vw;
  border-radius: 20px;
  margin: 7px;
  height: -webkit-max-content;
  height: max-content;
}

.contact-form-title {
  color: black;
  text-align: left;
  margin: 0px;
  font-size: large;
  margin: 30px 30px 7px 30px;
}

.contact-form-subtitle {
  color: black;
  text-align: left;
  font-size: smaller;
  margin: 7px 30px 15px 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-input {
  font-family: "Poppins";
  font-size: small;
  background-color: #edebf4;
  color: #575171;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  width: 90%;
  height: 41px;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 7px 0px;
}

.select-input {
  font-family: "Poppins";
  font-size: small;
  background-color: #edebf4;
  color: grey;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  height: 41px;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 7px 0px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding: 1rem;
  padding-right: 2rem;
  box-sizing: content-box;
  width: 90%;
}

.form-message-input {
  font-family: "Poppins";
  font-size: small;
  background-color: #edebf4;
  color: #575171;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  width: 90%;
  height: 100px;
  padding: 10px 10px 0px 10px;
  margin: 0px 0px 7px 0px;
  resize: none;
}

.form-submit-btn {
  cursor: pointer;

  background-color: #2aacd3;
  color: white;
  font-family: "Poppins";
  font-size: medium;
  text-decoration: none;
  border: none;

  width: 250px;
  height: 60px;
  border-radius: 20px;
  margin: 7px 0px 15px 0px;
  -webkit-appearance: none;
}

.mail-sent-confirmation h1 {
  color: black;
  text-align: center;
  margin: 0px;
  font-size: x-large;
  margin: 15px 15px 7px 15px;
}

.mail-sent-confirmation p {
  color: black;
  margin: 0px;
  text-align: center;
  font-size: medium;
  margin: 7px 30px 15px 30px;
}

.big-picture {
  border-radius: 25px;
  width: 80%;
  height: -webkit-max-content;
  height: max-content;
  margin-top: 40px;
}

/* nev hp design */

.homepage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: auto;
  width: 100%;
  padding: 30px;
  margin-bottom: 10px;
}

.hp-box {
  width: 600px;
  align-self: center;
  font-size: x-large;
  text-align: left;
  margin: 50px;
}

.hp-box-text {
  font-size: large;
  font-weight: normal;
}

h1 {
  font-size: 46px;
  padding: 0px;
  margin: 0px;
}

.hp-shortways {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.work-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 30px;
}

.work-box {
  width: 600px;
  height: 60vh;
  align-self: center;
  font-size: x-large;
  text-align: center;
  margin-top: 80px;
}

.button-ads {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  background-color: #ff4087;
  color: white;
  font-size: medium;
  -webkit-text-decoration: medium;
          text-decoration: medium;
  font-size: medium;
  font-weight: medium;
  font-family: "Poppins";

  width: 250px;
  padding: 0px 20px 0px 20px;
  height: 60px;
  border-radius: 20px;
  margin: 10px;
}

.inline-btn-ads {
  border: none;
  background: none;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
  color: blue;
  font-family: inherit;
  cursor: pointer;
}

/* press styles */

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  position: relative;
  width: 90%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  background-color: #353055;
}

.color-block {
  background-color: rgba(248, 247, 216, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-overlay {
  position: absolute;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.slider-constants {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.right-arrow {
  font-size: xx-large;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.left-arrow {
  font-size: xx-large;
  color: white;
  cursor: pointer;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.left-arrow:hover,
.right-arrow:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.slides-big-hl {
  font-size: 3vw;
  font-weight: bold;
  font-family: "Poppins";
  text-align: center;
}

.slides-texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.slides-paper {
  font-size: 3vw;
  font-weight: bold;
  font-family: "Poppins";
  margin-left: 5%;
}

.slides-text {
  font-size: 1.5vw;
  font-weight: lighter;
  font-family: "Poppins";
  margin-left: 5%;
}

.slides-body {
  font-size: 1.5vw;
  font-weight: medium;
  font-family: "Poppins";
  margin-left: 5%;
}

.slides-link {
  color: white;
  font-size: 1.5vw;
  font-weight: medium;
  font-style: italic;
  font-family: "Poppins";
}

.slider-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.image-color {
  background-color: yellow;
  position: absolute;
}

.press-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.press-text-containers {
  display: flex;
  flex-direction: row;
}

/* end ofpress styles */

/*custom player styles*/

.custom-player {
  position: relative;
  margin: 40px;
}

.play-btn {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  position: absolute;
  width: 50px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
}

.pause-btn {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  position: absolute;
  opacity: 0;
  width: 50px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  transition: opacity 0.3s;
}

.pause-btn:hover {
  opacity: 1;
  transition: opacity 0.1s;
}

/* end of custom-player styles */

@media screen and (max-width: 768px) {
  .emoji-image-1 {
    background-size: 400vw;
    background-position-x: left;
  }

  .emoji-image-2 {
    background-size: 500vw;
    background-position-x: -360vw;
  }

  .header-container-open,
  .header-container-closed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(59, 53, 93, 0.8);
    align-items: center;
    margin: 0px;
    padding: 20px;
    position: fixed;
    width: 100%;
  }

  .header-container-hidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(59, 53, 93, 0.8);
    align-items: center;
    margin: 0px;
    padding: 20px;
    position: fixed;
    width: 100%;
  }

  .header-items {
    display: none;
  }

  .header-item {
    cursor: pointer;
    font-weight: bold;
    margin: 0vw 1vw 0vw 1vw;
  }

  .sidebar-items {
    margin-right: 40px;
    margin-top: 30px;
    width: 290px;
    background-color: #3b355d;
    align-self: center;
    padding: 20px;
    border-radius: 20px;
    -webkit-filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.226));
            filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.226));
    margin-bottom: 100vh;
  }

  .sidebar-item {
    display: flex;
    cursor: pointer;
    font-weight: bold;
    margin: 10px 0px 10px 0px;
  }

  .selected-link {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 20px;
    background-color: #2aacd3;
    border-radius: 20px;
    border-bottom: none;
    margin: 0px 0px 0px 0px;
    font-size: medium;
  }

  .unselected-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    margin: 0px 5px 0px 5px;
    font-size: medium;
  }

  .unselected-link:hover {
    border-bottom: none;
    margin: 0px;
  }

  .hamburger-menu {
    cursor: pointer;
    align-self: flex-end;
    margin-right: 30px;
  }

  .hamburger-line {
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px;
  }

  .close-button {
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;
    margin-right: 35px;
  }
  .close-line-1 {
    width: 30px;
    height: 3px;
    background: white;
    -webkit-transform: translateY(3px) rotate(45deg);
            transform: translateY(3px) rotate(45deg);
  }

  .close-line-2 {
    width: 30px;
    height: 3px;
    background: white;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .modal-container {
    width: 330px;
    background-color: white;
    color: black;
    border-radius: 20px;
    margin: 7px;
  }

  .modal-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-title-text {
    font-size: large;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 15px 15px 0px 15px;
  }

  .modal-body-text {
    font-size: medium;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 7px 20px 7px 20px;
  }

  .blue-modal-btn {
    font-size: small;
  }

  .green-modal-btn {
    font-size: small;
    width: 50%;
  }

  .modal-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ff4087;
    color: white;
    font-size: medium;
    text-decoration: none;

    width: 250px;
    height: 60px;
    border-radius: 20px;
    margin: 10px;
  }

  .primetime-logo {
    width: 100px;
  }

  .title-header {
    font-size: large;
  }

  .title-subheader {
    font-size: medium;
    margin-bottom: 50px;
  }

  .primetime-description {
    font-size: small;
    text-align: left;
    margin: 10%;
  }

  [data-aos-delay] {
    transition-delay: 10 !important;
  }

  [data-aos] {
    transition: none;
  }

  .game-screenshots {
    margin: 50px;
    width: 100%;
  }

  div.faq-text-content h1 {
    font-size: large;
  }
  div.faq-text-content h2 {
    font-size: medium;
  }

  div.faq-text-content p {
    font-size: small;
  }

  .faq-text-content {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-header {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-container {
    padding: 10%;
    padding-bottom: 0px;
  }

  .p-work {
    text-align: center;
  }

  .h1-work {
    text-align: center;
  }

  .h1-privacy {
    text-align: center;
  }

  .h1-about {
    padding: 0px;
    margin: 0px;
    font-size: large;
  }

  .support-interactive-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titlecard-container {
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin-bottom: 7px;
  }

  .titlecard-title {
    font-size: large;
    padding: 0px;
    margin: 30px 30px 0px 30px;
    width: auto;
    height: auto;
  }

  .titlecard-body {
    font-size: medium;
    font-weight: normal;
    padding: 0px;
    margin: 0px 30px 30px 30px;
    width: auto;
    height: auto;
  }

  .cm-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #353055;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .open-cm-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .homepage-cm-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    width: 330px;
    border-radius: 20px;
    color: black;
    margin: 7px;
    transition: background-color 0.2s ease-in-out;
  }

  .insta-container {
    display: flex;
    flex-direction: column;
  }

  .cm-title-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cm-logo-and-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .cm-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin: 15px;
  }

  .cm-subtitle-text {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    font-weight: medium;
  }

  .cm-title-text {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .cm-body-container-open {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    transition: max-height 0.1s ease-in-out;
  }

  .cm-body-container-closed {
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;
  }

  .faq-question {
    font-size: large;
  }

  .faq-answer {
    font-size: medium;
  }

  .schedule-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    width: 330px;
    border-radius: 0px 0px 20px 20px;
    margin-bottom: 7px;
  }
  .schedule-content p,
  h2 {
    font-size: medium;
  }

  .schedule-content {
    padding: 20px;
  }

  .schedule-title-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .schedule-title {
    font-weight: bold;
  }

  .schedule-date {
    color: grey;
  }

  .schedule-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 10px;
  }

  .primetime-loading-gif {
    align-self: center;
    margin: 20px;
  }

  .quizzes-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    color: black;
    width: 330px;
    border-radius: 0px 0px 20px 20px;
    margin: 0px 7px 7px 7px;
  }

  .quiz-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 10px;
  }

  .quiz-content {
    padding: 20px;
  }

  .quiz-content p,
  a {
    font-size: medium;
  }

  .quiz-content a {
  }

  .quizzes-title-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .quizzes-title-header h2 {
    padding: 0px;
    margin: 0px;
    font-size: large;
  }
  .quiz-title {
    font-weight: bold;
    font-size: small;
  }

  .quiz-link {
    display: flex;
    text-decoration: none;
    background-color: #2aacd3;
    color: white;
    padding: 10px;
    margin: 0px 20px 0px 20px;
    height: 35px;
    border-radius: 20px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .watch-broadcast-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #2aacd3;
    color: white;
    text-decoration: none;

    width: 250px;
    height: 60px;
    border-radius: 20px;
  }

  .watch-broadcast-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .advertiser-description-container p {
    font-size: small;
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .pink-dot {
    background-color: #ec5387;
  }

  .blue-dot {
    background-color: #2aacd3;
  }

  .advertisers-container {
    padding: 10%;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .advertisers-description-container {
    background-color: white;
    color: black;
    font-size: medium;
    width: 330px;
    border-radius: 20px;
  }

  .advertisers-description-content {
    padding: 15px;
  }

  .advertisers-description-title {
    display: flex;
    justify-content: center;
    font-size: large;
    font-weight: bold;
    margin-top: 15px;
  }

  .advertisers-description-body {
    margin: 10px 0px 10px 0px;
  }

  .advertisers-description-body h2 {
    font-size: medium;
    margin: 0px;
  }

  .advertisers-description-body p {
    font-size: medium;
    margin: 0px;
  }

  .advertisers-description-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .advertisers-description-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ff4087;
    color: white;
    font-size: small;

    width: 250px;
    height: 60px;
    border-radius: 20px;
  }

  .partners-description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 330px;
    border-radius: 20px;
    background-color: white;
    color: black;
    margin: 7px 0px 7px 0px;
  }

  .partners-description-content {
    padding: 15px;
  }

  .partners-description-text {
  }

  .partners-description-title {
    margin: 0px;
    margin-top: 5px;
    font-size: large;
    width: 80%;
  }

  .partners-description-body {
    margin: 0px;

    font-size: medium;
  }

  .partner-description-dots {
    margin: 5px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .partner-description-dot {
    margin: 5px;
    display: flex;
    flex-direction: row;
    font-size: small;
    font-weight: lighter;
    justify-content: flex-start;
    align-items: center;
  }

  .dtc-container {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-wide {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-wide-about-us {
    width: 330px;
    background-color: #353055;
    border-radius: 20px;
    margin: 7px;
  }

  .dtc-content {
    padding: 15px;
  }

  .dtc-pretitle {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    margin-top: 5px;
  }

  .dtc-title {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .ltc-container {
    width: 330px;
    background-color: #fff;
    border-radius: 20px;
    margin: 7px;
    color: black;
  }

  .ltc-content {
    padding: 15px;
    margin-bottom: 10px;
  }

  .ltc-pretitle {
    margin: 0px;
    padding: 0px;
    font-size: medium;
    margin-top: 5px;
  }

  .ltc-title {
    margin: 0px;
    padding: 0px;
    font-size: x-large;
  }

  .page-content-homepage {
    padding-top: 20vw;
  }

  .homepage-start-container {
    margin-bottom: 0px;
    height: auto;
  }

  .dark-modal-container {
    background-color: #353055;
    color: white;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
  }

  .dark-modal-content {
    padding: 15px;
  }

  .dark-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .dm-emoji-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 34, 73);
    width: 70px;
    height: 70px;
    font-size: 48px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .dark-modal-header-text {
  }

  .dm-subtitle-text {
    margin: 0px;
    padding: 0px;
    font-size: small;
    font-weight: medium;
  }

  .dm-title-text {
    margin: 0px;
    padding: 0px;
    font-size: large;
  }

  .dark-modal-body {
    font-size: small;
  }

  .dark-modal-body p {
    margin: 10px 0px 0px 0px;
  }

  .screenshot-section {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
  }
  .game-screenshot-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .game-screenshot {
    width: 250px;
    /* filter: drop-shadow(0px 0px 20px #00000083); */
    /* margin:40px;  */
  }

  .first-row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-top: 30px;
    overflow: hidden;
  }

  .second-row-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0px;
    overflow: hidden;
    overflow-y: visible;
  }

  .thrd-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fourth-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fifth-row-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .map {
    width: 280px;
  }

  div.faq-text-content h1 {
    font-size: large;
  }
  div.faq-text-content h2 {
    font-size: medium;
  }

  div.faq-text-content p {
    font-size: small;
  }

  div.faq-text-content-about p {
    font-size: small;
  }

  .faq-text-content-about {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-header {
    width: 80vw;
    padding: 10%;
  }

  .faq-text-container-about {
    padding: 20%;
    padding-top: 4vw;
    padding-bottom: 0vw;
  }

  .faq-text-container-header {
    padding: 20%;
    padding-top: 20vw;
    margin-top: 0px;
    padding-bottom: 0vw;
  }

  .dark-container {
    padding-top: 8vw;
    padding-bottom: 5vw;
  }

  .p-work {
    text-align: center;
  }

  .h1-work {
    text-align: center;
  }

  .h1-about {
    padding: 0px;
    margin: 0px;
  }

  .card-container {
    max-width: 90vw;
    margin: 0px;
    display: flex;
    margin-left: 0vw;
    margin-right: 0vw;
    align-self: center;
  }

  .card-grid {
    display: flex;
    flex-direction: column;
  }

  .card-br {
    line-height: 100px;
  }

  .contact-form-super-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 330px;
    border-radius: 20px;
    margin: 7px;
    min-height: 420px;
  }

  .contact-form-title {
    color: black;
    text-align: center;
    margin: 0px;
    font-size: large;
    margin: 30px 30px 7px 30px;
  }

  .contact-form-subtitle {
    color: black;
    text-align: center;
    font-size: medium;
    margin: 7px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: #575171;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    width: 280px;
    height: 41px;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
  }

  .select-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: grey;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    height: 41px;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    padding: 1rem;
    padding-right: 2rem;
    box-sizing: content-box;
    width: 280px;
  }

  .form-message-input {
    font-family: "Poppins";
    font-size: small;
    background-color: #edebf4;
    color: #575171;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    width: 280px;
    height: 100px;
    padding: 10px 10px 0px 10px;
    margin: 0px 0px 7px 0px;
    resize: none;
  }

  .form-submit-btn {
    cursor: pointer;
    background-color: #2aacd3;
    color: white;
    font-family: "Poppins";
    font-size: medium;
    text-decoration: none;
    border: none;
    width: 250px;
    height: 60px;
    border-radius: 20px;
    margin: 7px 0px 15px 0px;
    -webkit-appearance: none;
  }

  .mail-sent-confirmation h1 {
    color: black;
    text-align: center;
    margin: 0px;
    font-size: x-large;
    margin: 15px 15px 7px 15px;
  }

  .mail-sent-confirmation p {
    color: black;
    margin: 0px;
    text-align: center;
    font-size: medium;
    margin: 7px 30px 15px 30px;
  }

  /* new hp design */

  .homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
    width: 70%;
    margin-bottom: 0px;
  }

  .hp-box {
    width: 330px;
    align-self: center;
    font-size: large;
    text-align: left;
    padding-bottom: 0px;
    margin: 50px;
    background-color: white;
    color: black;
    border-radius: 20px;
  }

  .hp-box-text {
    font-size: medium;
    font-weight: lighter;
  }

  h1 {
    font-size: 34px;
    padding: 0px;
    margin: 0px;
  }

  .hp-shortways {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .work-container {
    display: flex;
    flex-direction: column;
    width: 330px;
    margin-top: 30px;
  }

  .work-box {
    width: 330px;
    align-self: center;
    font-size: medium;
    text-align: center;
    margin-top: 80px;
  }

  .big-picture {
    border-radius: 25px;
    width: 330px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 40px;
  }

  .slider {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-image {
    position: static;
    width: 330px;
    height: 169px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    background-color: #353055;
  }

  .color-block {
    background-color: rgba(248, 247, 216, 0.7);
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slider-overlay {
    position: static;
    width: 330px;
    height: 100%;
    background-color: #ec5387;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
  }

  .slider-constants {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
  }

  .right-arrow {
    font-size: xx-large;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .left-arrow {
    font-size: xx-large;
    color: white;
    cursor: pointer;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .left-arrow:hover,
  .right-arrow:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .slides-big-hl {
    font-size: large;
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
  }

  .slides-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .slides-paper {
    font-size: large;
    font-weight: bold;
    font-family: "Poppins";
    margin-left: 15px;
    margin-bottom: 0px;
  }

  .slides-text {
    font-size: medium;
    font-weight: lighter;
    font-family: "Poppins";
    margin: 15px;
  }

  .slides-body {
    font-size: medium;
    font-weight: normal;
    font-family: "Poppins";
    margin-left: 15px;
  }

  .slides-link {
    color: white;
    font-size: medium;
    font-weight: medium;
    font-style: italic;
    font-family: "Poppins";
  }

  .slider-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .image-color {
    background-color: yellow;
    position: absolute;
  }

  .press-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  .press-text-containers {
    display: flex;
    flex-direction: row;
  }
}

